<template>
  <div :class="['login-box']">
    <div v-if="isLoading"></div>
    <div :class="[{ 'banner-box': !isMobile }]">
      <div
        :class="[{ 'login-content': isMobile }, { 'banner-left': !isMobile }]"
      >
        <div class="title-login">
          <div>{{ getSloganTitle }}</div>
          <div class="title-url cursor" @click="goToPage">
            https://tsslabs.com/
          </div>
          <div>{{ getSloganTitleSecond }}</div>
        </div>
        <!--        <div :class="[{ 'btn-login': isMobile }]">-->
        <!--          <v-btn-->
        <!--            width="100%"-->
        <!--            height="58px"-->
        <!--            color="#efb82d"-->
        <!--            :elevation="vDefaultElevation"-->
        <!--            @click="connectMetamaskBtnFun"-->
        <!--            class="login-btn"-->
        <!--          >-->
        <!--            {{ $t("login.connectWallet") }}-->
        <!--          </v-btn>-->
        <!--        </div>-->
      </div>
      <!--      <div class="banner-right" v-if="!isMobile">-->
      <!--        <img class="login-banner" src="/static/image/login_banner.png" />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Login from "@/views/script/login.js";
import { getProvider } from "@/utils/connet";
export default {
  name: "Login",
  mixins: [Login],
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
      vDefaultElevation: (state) => state.system.vDefaultElevation,
      token: (state) => state.user.token,
      language: (state) => state.system.language,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    getSloganTitle() {
      let ja = `BIDEXにお越し頂き、ありがとうございます。
\n
BIDEXのステーキングサービスは今後TSSにシステムを移行するため、16日22時よりシステム利用を一時停止とさせていただきます。
17日13時頃にシステム移行が完了予定となっています。
\n
システム移行後はTSSの下記のサイトにアクセスをお願い致します。
`;
      let en = `Thank you for visiting BIDEX.
\n
BIDEX's staking service will be temporarily suspended from 22:00 on the 16th in order to migrate the system to TSS.
The system migration is scheduled to be completed around 13:00 on the 17th.
\n
After the system migration, please access the following TSS site.
`;
      let text = this.language === "jp" ? ja : en;
      return text;
    },
    getSloganTitleSecond() {
      let ja = `BIDEXは今後DEXとして機能する準備を行なっていきます。
様々な機能を充実させていくものとなりますのでご期待下さい。
\n
今後とも宜しくお願い致します。
`;
      let en = `BIDEX will prepare to function as DEX in the future.
Please look forward to it as it will be enriched with various functions.
\n
Sincerely.
`;
      let text = this.language === "jp" ? ja : en;
      return text;
    },
  },
  data() {
    return {};
  },
  created() {
    getProvider();
  },
  methods: {
    goToPage() {
      window.location.href = "https://tsslabs.com/";
    },
  },
};
</script>

<style scoped lang="scss">
.title-login {
  font-family: sans-serif;
  color: #333333;
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
  white-space: pre-line;
}

.title-url {
  color: #0088cc;
  margin: 20px 0;
  text-decoration: underline;
}

.login-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: url("/static/image/logo_dark.png") repeat;
}

.banner-box {
  width: 1000px;
  overflow: hidden;
  margin: 0 auto;
}

.banner-left {
  float: left;
}

.banner-right {
  float: right;
}

.banner-left,
.banner-right {
  position: relative;
  margin-top: 10vh;
}

.login-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 4vh;
}

.login-banner {
  width: 400px;
  height: auto;
}

.btn-login {
  position: absolute;
  width: 90%;
  left: 5%;
  bottom: 10vh;
}

.padding-64 {
  padding: 64px;
}

.login-btn {
  font-size: 22px;
}

@media only screen and (min-width: 768px) {
  .title-login {
    line-height: 30px;
    font-size: 26px;
    font-weight: bold;
  }
}
</style>
